<template>
  <v-container fluid>
    <v-row>

      <v-col v-for="category in complianceCategories" :key="'category-' + category.id" cols="12" md="3" sm="6">
        <v-hover v-slot="{ hover }" open-delay="20">
          <v-card
            height="500"
            :elevation="hover ? 16 : 0"
            color="indigo lighten-5"
            class="mx-auto d-flex flex-column justify-space-between"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              :src="category.featuredImage || 'https://via.placeholder.com/200'"
              alt="Categoria"
            />
            <v-card-subtitle class="text-uppercase pt-5 px-6 pb-0">
              {{ category.title }}
            </v-card-subtitle>
            <v-card-text class="py-0 px-2 text-card">
              <v-card-title style="word-break: break-word">
                {{ category.title }}
              </v-card-title>
            </v-card-text>
            <v-card-actions>
              <v-btn
                    class="ml-5 mb-5"
                    outlined
                    color="secondary"
                    :to="`${ redirectToExternalPage() ? '/external' : '' }/category/${category.id}`">
                    Ver mais
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AllCategory } from "../services/compliance-category-service";

export default {
  name: "ComplianceGrid",
  data: () => ({
    articles: [],
    filteredArticles: [],
    categories: [],
    selectedCategory: null,
    complianceCategories: []
  }),

  async created() {
    this.complianceCategories = await AllCategory();
  },

  methods: {
    redirectToExternalPage() {
      return this.$route.name && this.$route.name.startsWith('external');
    },
  },
};
</script>

<style scoped>
.text-card {
  color: rgba(0, 0, 0, 0.7);
}
</style>
